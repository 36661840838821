.videoContainer {
  max-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FAF8F1;
  padding: 0;
}

.playerWrapper {
  width: 100%;
  max-width: calc(50vh * (640/360)); /* This ensures the video height won't exceed 50vh while maintaining aspect ratio */
  margin: 0 auto;
  padding: 0;
}

.sectionContent {
    padding: 0;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.contentOverlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
  padding: 0 20px;
}